import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { DOCUMENT_MODEL } from "../../../../../../@shared/components/contract-editor/contract-editor.service";
import { IUser } from "../../../../../../@core/models/model";
import { AuthenticationService } from "../../../../../../@core/services/authentication.service";
import { ActivatedRoute } from "@angular/router";
import { MarkdownModule } from "ngx-markdown";
import { ContractService } from "../../../../../services/contracts.service";
import { IContract } from "../../../../../models/contract-management.model";
import { CommonModule, DatePipe } from "@angular/common";
import { TruncatePipe } from "../../../../../../@shared/pipes/truncate.pipe";
import { BadgeComponent } from "../../../../../../@shared/components/badge/badge.component";
import { CompareSelectionComponent } from "../compare-selection/compare-selection.component";
import { MatDialog } from "@angular/material/dialog";
import { SkeletonModule } from "primeng/skeleton";
import { ChatComponent, ChatSource } from "../../../../chat/chat.component";
import { CompareService } from "../compare.service";
import { BackComponent } from "../../../../versions/components/back/back.component";
import { TopBarComponent } from "../../../../../../app-layout/components/top-bar/top-bar.component";
import { ButtonModule } from "primeng/button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SplitterModule } from "primeng/splitter";
import { IFile } from "../../../../settings/repository/repository.types";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DialogModule } from 'primeng/dialog';
import { Sidebar, SidebarModule } from "primeng/sidebar";
import { CompareHistoryComponent } from "../compare-history/compare-history.component";
import { ScrollResetDirective } from "../../../../../../@shared/directives/scroll-reset.directive";
import { ToastService } from "../../../../../../@core/services/toast.service";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { environment } from "../../../../../../../environments/environment.dev";
import { FormatTextPipe } from "../../../../../../@shared/pipes/formated-text.pipe";
import { TooltipModule } from "primeng/tooltip";
@Component({
    selector: 'app-compare-view',
    standalone: true,
    imports: [
        MarkdownModule,
        TruncatePipe,
        CommonModule,
        SkeletonModule,
        ChatComponent,
        TopBarComponent,
        ButtonModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        SplitterModule,
        MatSlideToggleModule,
        DialogModule,
        SidebarModule,
        CompareHistoryComponent,
        ScrollResetDirective,
        ProgressSpinnerModule,
        FormatTextPipe,
        TooltipModule
    ],
    templateUrl: './compare-view.component.html',
    styleUrl: './compare-view.component.scss',
    providers: []
})
export class CompareViewComponent implements OnInit {

    @ViewChild('editableContent') editableContent!: ElementRef;

    loading = true;
    compareResult: string = '';
    kpiResult!: { _id: string, aspect: string, comparison: string, generateTable: boolean, generateGraph: boolean }[];
    panelSizes: number[] = [75, 25];
    currentUser!: IUser | null | undefined
    documentId!: string
    comparedDocumentId!: string
    documentType!: string
    document!: IContract & { summary: string }
    comparedDocument!: IContract & { summary: string }
    compareDocumentsIds: { _id: string, docModel: string, name: string }[] = [];
    compareDocuments: (IContract | IFile & { summary: string })[] = [];
    isExpanded = false;
    compareType!: DOCUMENT_MODEL;
    COMPARISON_LIMIT = 9;
    toggleList: boolean[] = []
    comparedList: boolean[] = []
    enableCompare: boolean = false;
    toggleKpiDailog: boolean = false;
    toogleComparison: boolean = false;
    selectedKpi!: { aspect: string, comparison: string };
    isKpiLoading: boolean = false;
    isSummaryLoading: boolean = false;
    changeDocLoading: boolean = false;
    downloadLoading: boolean = false;
    showHistory: boolean = false;
    compareId: IContract['_id'] = '';
    comparisonExists: boolean = false;
    compareName: string = 'Compare Docs';
    kpiDialog: {
        mode: 'Add' | 'Edit',
        visible: boolean,
        index: number
    } = { mode: 'Add', visible: false, index: 0 }

    kpiName: string = ''
    kpiDescription: string = ''
    ChatSource = ChatSource
    editName: boolean = false;

    kpiCompareTableDialog: {
        visible: boolean,
        loading: boolean
        table: string
    } = { visible: false, loading: false, table: '' }

    kpiCompareGraphDialog: {
        visible: boolean,
        loading: boolean
        graph: string,
        graphType: string
    } = { visible: false, loading: false, graph: '', graphType: '' }

    graphCache: { [kpiId: string]: { graph: string, graphType: string } } = {};
    suggestions: string[] = [
        'At what percentages the Daily/Weekly/Monthly rates changed ',
        'What is the highest rate increase in terms of monetary value and percentage for a specific item ?',
        'List the items that faced a rate decrease',
        'Which items had no change in their rates ?',
        'Which is the best quote among these quotes ?',
        'Compare the rates in the tables and give a synopsis'
    ]

    constructor(
        private dialog: MatDialog,
        private route: ActivatedRoute,
        private authService: AuthenticationService,
        private compareService: CompareService,
        private contractsService: ContractService,
        private toast: ToastService,
        private cdr: ChangeDetectorRef
    ) {
        this.authService.currentUser.subscribe(res => {
            this.currentUser = res
        });
        this.route.queryParamMap.subscribe(params => {

            this.compareId = params.get('compareId') as string;
            if (this.compareId) {
                this.comparisonExists = true;
            }
            else {
                this.documentId = params.get('documentId') as string;
                this.comparedDocumentId = params.get('comparedDocumentId') as string;
                let compareDocName = params.get('comparedDocumentName') as string;
                this.compareType = params.get('compareType') as DOCUMENT_MODEL;
                this.documentType = params.get('documentType') as string;
                this.compareDocumentsIds.push({
                    _id: this.comparedDocumentId,
                    docModel: this.compareType,
                    name: compareDocName
                });
                this.toggleList = new Array(this.compareDocumentsIds.length).fill(true);
                this.comparedList = new Array(this.compareDocumentsIds.length).fill(false);
            }
        });
    }

    async ngOnInit() {
        this.loading = true;
        if (this.comparisonExists) this.getComparison();
        else await this.getComparedDocuments();
    }

    getComparison() {
        this.compareService.getComparison(this.compareId).subscribe((res) => {
            if (res) {
                this.document = res.data.contract;
                this.compareName = res.data.name;
                this.documentId = this.document._id;
                this.documentType = this.document.linkedDocType._id;
                this.compareDocuments = res.data.compareContracts.map((doc: any) => {
                    return doc.doc
                });
                this.compareDocumentsIds = res.data.compareContracts.map((doc: any) => {
                    return {
                        _id: doc._id,
                        docModel: doc.docModel,
                        name: doc.doc.name
                    }
                });
                this.compareResult = res.data.compare;
                this.kpiResult = res.data.kpiComparison;
                this.toggleList = new Array(this.compareDocumentsIds.length).fill(true);
                this.comparedList = new Array(this.compareDocumentsIds.length).fill(true);
                this.loading = false;
            } else {
                this.compareResult = 'No comparison result found';
                this.loading = false;
            }
        });
    }

    goBack() {
        window.close();
    }

    blurSpan(event: Event) {
        const target = event.target as HTMLElement;
        target.blur();
    }

    updateContractName(event: Event) {
        event.preventDefault()
        const target = event.target as HTMLElement;
        const newName = target.textContent?.trim() || 'Untitled';
        target.innerHTML = newName
        if (this.compareName && this.compareName !== newName) {
            this.compareName = newName;
            this.updateCompareName();
        }
    }

    updateCompareName() {
        this.compareService.updateComparisonName(this.compareId, this.compareName).subscribe((res) => {
            if (res && res.success) {
                this.editName = false;
                this.toast.success("Name updated successfully")
            }
        });
    }



    async getComparedDocuments(enable?: boolean) {
        this.contractsService.getCompareDocuments({ document: this.documentId, compareDocuments: this.compareDocumentsIds, compareType: this.compareType }).subscribe(async (res) => {
            if (res && res.success) {
                this.document = res.data.document;
                this.compareDocuments = res.data.compareDocuments;
                this.enableCompare = enable ? enable && this.checkEnableCompare() : true;
                this.changeDocLoading = false;
                if (!enable) {
                    await this.getCompareResult();
                    await this.getCompareKpisResult();
                }
            } else {
                this.compareResult = 'No comparison result found';
                this.loading = false;
            }
        });
    }
    async getCompareResult() {
        this.isSummaryLoading = true;
        this.compareDocumentsIds = this.compareDocumentsIds.filter((doc, indx) => this.toggleList[indx]);
        this.compareService.getCompareResult({ document: this.documentId, compareDocuments: this.compareDocumentsIds, docModel: this.compareType }).subscribe((res) => {
            if (res) {
                this.compareDocumentsIds.forEach((doc, index) => this.comparedList[index] = true);
                if (res.status != '[DONE]') {
                    this.compareResult += res.content.message;
                }
                else if (res.status == '[DONE]' && !this.compareResult) {
                    this.compareResult = res.content.message;
                    this.isSummaryLoading = false;
                }
                else {
                    this.compareResult = res.content.message;
                    this.compareName = res.content.comparisonName;
                    this.compareId = res.content.compareId;
                    this.isSummaryLoading = false;
                }
                this.isSummaryLoading = false;
                this.loading = false;
                this.enableCompare = this.checkEnableCompare();
            } else {
                this.compareResult = 'No Summary result found';
                this.loading = false;
            }
        })
    }

    async getCompareKpisResult() {
        this.isKpiLoading = true;
        this.compareDocumentsIds = this.compareDocumentsIds.filter((doc, indx) => this.toggleList[indx]);
        this.compareService.getCompareKpiResult({ document: this.documentId, compareDocuments: this.compareDocumentsIds, docModel: this.compareType }).subscribe((res) => {
            if (res) {
                this.graphCache = {};
                this.compareDocumentsIds.forEach((doc, index) => this.comparedList[index] = true);
                this.kpiResult = res.content.kpiResult;
                this.loading = false;
                this.enableCompare = false;
                if (res.status == '[DONE]') {
                    this.isKpiLoading = false;
                    this.compareName = res.content.comparisonName;
                    this.compareId = res.content.compareId;
                    this.enableCompare = this.checkEnableCompare();
                }
            } else {
                // this.kpiResult = 'No comparison result found';
                this.loading = false;
            }
        })
    }

    openKpi(item: { _id: string, aspect: string, comparison: string, generateTable: boolean, generateGraph: boolean }) {
        this.toggleKpiDailog = true;
        this.selectedKpi = item;
    }

    openTable(item: { _id: string, aspect: string, comparison: string, generateTable: boolean, generateGraph: boolean }) {
        this.kpiCompareTableDialog.visible = true;
        this.kpiCompareTableDialog.loading = true;
        this.selectedKpi = item;
        this.compareDocumentsIds = this.compareDocumentsIds.filter((doc, indx) => this.toggleList[indx]);
        const compareDocIds = this.compareDocumentsIds.filter((doc, index) => this.comparedList[index]);
        this.compareService.getKpiCompareTable({ document: this.documentId, compareDocuments: compareDocIds, kpi: item._id }).subscribe((res) => {
            if (res && res.success) {
                this.kpiCompareTableDialog.table = res.data.table;
                this.kpiCompareTableDialog.loading = false;
            } else {
                this.toast.error('Error fetching the comparison table.');
                this.kpiCompareTableDialog.loading = false;
            }
        })
    }

    openGraph(item: { _id: string, aspect: string, comparison: string, generateTable: boolean, generateGraph: boolean }) {
        this.kpiCompareGraphDialog.visible = true;
        this.kpiCompareGraphDialog.loading = true;
        this.selectedKpi = item;
        if (this.graphCache[item._id]) {
            this.kpiCompareGraphDialog.graph = this.graphCache[item._id].graph;
            this.kpiCompareGraphDialog.graphType = this.graphCache[item._id].graphType;
            this.kpiCompareGraphDialog.loading = false;
        } else {
            this.compareDocumentsIds = this.compareDocumentsIds.filter((doc, indx) => this.toggleList[indx]);
            const compareDocIds = this.compareDocumentsIds.filter((doc, index) => this.comparedList[index]);
            this.compareService.getKpiCompareGraph({ document: this.documentId, compareDocuments: compareDocIds, kpi: item._id }).subscribe((res) => {
                if (res && res.success) {
                    const graphUrl = res.data.graph;
                    const graphType = res.data.graphType;
                    this.graphCache[item._id] = { graph: graphUrl, graphType: graphType };
                    this.kpiCompareGraphDialog.graph = graphUrl;
                    this.kpiCompareGraphDialog.graphType = graphType;
                    this.kpiCompareGraphDialog.loading = false;
                } else {
                    this.toast.error('Error fetching the comparison graph.');
                    this.kpiCompareTableDialog.loading = false;
                }
            })
        }
    }

    changeCompareDocument() {
        const _dialogRef = this.dialog.open(CompareSelectionComponent, {
            disableClose: true,
            width: '40vw',
            maxHeight: '80vh',
            data: {
                contractId: this.documentId,
                selectedDocuments: this.compareDocumentsIds,
                documentType: this.documentType
            }
        });
        _dialogRef.afterClosed().subscribe(doc => {
            if (doc) {
                this.changeDocLoading = true;
                this.compareType = doc.compareType;
                this.compareDocumentsIds.push({
                    _id: doc.contract._id,
                    name: doc.contract.name,
                    docModel: doc.compareType
                });
                this.toggleList.push(true);
                this.getComparedDocuments(true);
            }
        })
    }

    removeDocument(doc: (IContract | IFile & { summary: string })) {
        this.compareDocumentsIds = this.compareDocumentsIds.filter(document => document._id !== doc._id);
        this.enableCompare = this.compareDocumentsIds.length > 0 && this.checkEnableCompare();
        this.getComparedDocuments(true);
    }

    toggleChange(event: any, index: number) {
        this.toggleList[index] = event.checked;
        this.enableCompare = this.toggleList.some(toggle => toggle) && this.checkEnableCompare();
    }
    downloadComparison() {
        this.downloadLoading = true
        this.compareService.downloadComparison({ document: this.documentId }).subscribe((res) => {
            if (res) {
                const blob: Blob = new Blob([new Uint8Array(res.data.data)], {
                    type: 'application/pdf'
                });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = `Comparison.pdf`;
                document.body.append(link);
                link.click();
                link.remove();
                this.downloadLoading = false;
            }
        })
    }

    async compareDocs() {
        this.enableCompare = false;
        await this.getCompareResult();
        await this.getCompareKpisResult();
    }

    checkEnableCompare() {
        return !this.isSummaryLoading && !this.isKpiLoading;
    }

    openAddKpiDialog(mode: 'Add' | 'Edit' = 'Add', index: number = 0) {
        this.kpiDialog.visible = true
        this.kpiDialog.mode = mode
    }


    onKpisDialogClose() {
        this.kpiName = ''
        this.kpiDescription = ''
    }

    saveKpi() {
        if (!this.kpiName?.trim()?.length || !this.kpiDescription?.trim()?.length) {
            return this.toast.warn("Please fill all the required fields")
        }
        this.isKpiLoading = true;
        this.compareDocumentsIds = this.compareDocumentsIds.filter((doc, indx) => this.toggleList[indx]);
        this.kpiDialog.visible = false
        this.compareService.getCustomKpi({ document: this.documentId, compareDocuments: this.compareDocumentsIds, customKpis: [{ comparison_aspect: this.kpiName, description: this.kpiDescription }] }).subscribe((res) => {
            if (res) {
                let newCustomKpis = res.content.kpiResult;
                this.kpiResult = [...newCustomKpis, ...this.kpiResult,];
                this.loading = false;
                this.enableCompare = false;
                if (res.status == '[DONE]') {
                    this.isKpiLoading = false;
                    this.compareName = res.content.compareName;
                    this.compareId = res.content.compareId;
                    this.kpiName = ''
                    this.kpiDescription = ''
                }
            } else {
                this.isKpiLoading = false;
                this.loading = false;
            }
        }, error => {
            this.isKpiLoading = false;
            this.loading = false;
        })

    }

    enableEditing() {
        this.editName = true;
        this.cdr.detectChanges();
        setTimeout(() => {
            this.editableContent.nativeElement.focus();
        }, 0);
    }
}