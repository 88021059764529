@if (currentScreenComponent){
<div class="flex flex-row gap-2 font-semibold h-[5%]">
    <p-button label="Go Back" icon="pi pi-angle-left" (click)="goBack()" styleClass="text-sm"></p-button>
</div>
}
<div class="h-[95%]">
    @switch (currentScreenComponent) {
    @case (CURRENT_SCREEN_WINDOW.TRANSALTE) {
    <app-translate [contractId]="contractId"></app-translate>
    }
    @case(CURRENT_SCREEN_WINDOW.CHECKPOINTS) {
    <app-check-points [contractId]="contractId"></app-check-points>
    }
    @case (CURRENT_SCREEN_WINDOW.SUMMARY) {
    <app-summary [contractId]="contractId"></app-summary>
    }

    @case (CURRENT_SCREEN_WINDOW.KEY_HIGHLIGHTS) {
    <app-key-highlights [contractId]="contractId" />
    }
    @case (CURRENT_SCREEN_WINDOW.COMPARE) {
    <app-compare-history [documentId]="contractId" [documentType]="this.documentType"
        [navRequired]="true"></app-compare-history>
    }

    @default {
    <div class="w-full flex flex-col p-1.5">
        <div class="flex flex-row justify-between items-center">
            @if(isRefreshing && refreshingUser) {
            <div class="text-xs text-gray-500 max-w-[60%] mt-1"> Currently generating insights by {{refreshingUser}}
            </div>
            }
            @else if(latestInsightDetails) {
            <div class="text-xs text-gray-500 max-w-[60%] mt-1">Insights last generated by
                {{latestInsightDetails.username}} on {{latestInsightDetails.updatedAt| date:'mediumDate'}} at
                {{latestInsightDetails.updatedAt| date:'shortTime'}}
            </div>
            }
            <div class="ml-auto">
                <div
                    class="shadow-md shadow-orange-100 transition-all duration-300 ease-in-out active:scale-95 active:transform ">
                    <p-button (click)="generateInsights()" [disabled]="isRefreshing">
                        <div class="insights-button flex flex-row items-center gap-1">
                            @if(isRefreshing) {
                            <i class="pi pi-spinner-dotted pi-spin mr-2"></i>
                            }
                            @else {
                            <img src="icons/star.svg" pTooltip="AI Generated" tooltipPosition="top" class=" h-5 w-5"
                                alt="Star icon">
                            }
                            Generate Insights
                        </div>
                    </p-button>
                </div>
            </div>
        </div>
        @if(isRefreshing) {
        <div class="flex flex-col">
            <div class="p-2 animate-pulse">
                <div class="col-span-2 flex flex-col justify-between p-12 bg-gray-200 rounded-lg shadow-sm">
                </div>
            </div>

            <div class="p-2 animate-pulse">
                <div class="col-span-2 flex flex-col justify-between p-24 bg-gray-200 rounded-lg shadow-sm">
                </div>
            </div>

            <div class="grid grid-cols-2 gap-4 p-2 animate-pulse">
                <div class="flex flex-col justify-between p-12  bg-gray-200 rounded-lg shadow-sm">
                </div>

                <div class="flex flex-col justify-between p-12  bg-gray-200 0 rounded-lg shadow-sm">
                </div>

                <div class="col-span-2 flex flex-col justify-between p-12 bg-gray-200 rounded-lg shadow-sm">
                </div>
                <div class="col-span-2 flex flex-col justify-between p-12 bg-gray-200 rounded-lg shadow-sm">
                </div>
            </div>
        </div>

        }
        @else{
        <div class="summary-card cursor-pointer  mt-3 relative w-full overflow-hidden"
            (click)="openComponent(CURRENT_SCREEN_WINDOW.SUMMARY)">
            <div class="rounded-lg ">
                <div class="p-3 pr-[10rem]">
                    <h2 class="font-semibold text-base ">Summary</h2>
                    <p class="text-sm "> Access a comprehensive summary of your document</p>
                    <img class="h-[13rem] w-[9rem] absolute bottom-4 right-[3%] translate-y-[50%] z-10 object-contain"
                        src="images/summary-image.png" alt="image">
                </div>
            </div>
        </div>

        <div class="flex gap-2 cursor-pointer mt-3">
            <div class="relative card min-h-[12rem] border">
                <div class="relative cursor-pointer min-h-[12rem]"
                    (click)="openComponent(CURRENT_SCREEN_WINDOW.KEY_HIGHLIGHTS)">
                    <img class="h-[10rem] w-[12rem] absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[50%] z-10 object-contain"
                        src="images/insights-image.png" alt="image">
                    <div class="rounded-lg flex z-10 card-pb">
                        <div class="p-3 z-[1000]">
                            <div class="flex flex-row items-center">
                                <h2 class="font-semibold text-base mr-2">Tags</h2>
                            </div>

                            <p class="text-sm ">Review the key information of the document</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="relative card cursor-pointer min-h-[12rem] border"
                (click)="openComponent(CURRENT_SCREEN_WINDOW.CHECKPOINTS)">
                <div class="relative  min-h-[12rem]">
                    <img class="h-[13rem] w-[9rem] absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[50%] z-10 object-contain"
                        src="images/checklists.png" alt="image">
                    <div class="rounded-lg flex z-10 card-pb">
                        <div class="p-3 z-[1000]">
                            <div class="flex flex-row items-center">
                                <h2 class="font-semibold text-base mr-2">Checklist Items</h2>
                            </div>

                            <p class="text-sm ">Review checklists, compliance, and risk score for the
                                document.</p>
                        </div>
                    </div>
                </div>
                <p-badge *ngIf="checklistCount" class="absolute top-[-0.35rem] right-[-0.35rem]"
                    [value]="checklistCount"></p-badge>
            </div>
        </div>
        <div class="summary-card border mt-3 relative w-full overflow-hidden cursor-pointer "
            (click)="openComponent(CURRENT_SCREEN_WINDOW.TRANSALTE)">
            <div class="rounded-lg ">
                <div class="p-3 pr-[10rem]">
                    <h2 class="font-semibold text-base ">AI Tooling</h2>
                    <p class="text-sm"> Translate to Simple Language</p>
                    <img class="h-[13rem] w-[9rem] absolute bottom-[3rem] right-[3%] translate-y-[50%] z-10 object-contain"
                        src="images/ai_tooling.png" alt="image">
                </div>
            </div>
        </div>
        <div class="summary-card border mt-3  relative w-full overflow-hidden cursor-pointer "
            (click)="openComponent(CURRENT_SCREEN_WINDOW.COMPARE)">
            <div class=" rounded-lg ">
                <div class="p-3 pr-[10rem]">
                    <h2 class="font-semibold text-base ">Compare</h2>
                    <p class="text-sm"> Compare multiple documents using AI.</p>
                    <img class="h-[13rem] w-[9rem] absolute bottom-[3rem] right-[3%] translate-y-[50%] z-10 object-contain"
                        src="images/compare.png" alt="image">
                </div>
            </div>
        </div>
        }
    </div>
    }
    }
</div>