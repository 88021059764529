import { MenuItem } from "primeng/api";
import { IUser } from "../../../@core/models/model";

export interface ChatMessage {
    sender: SENDER_TYPE;
    isLoading: boolean;
    timestamp: string;
    content: string;
    contentType: CONTENT_TYPE;
    meta?: {
        tags?: {
            label: string;
            id: string;
            type: string;
        }[];
        options?: (MenuItem & { description: string, type: string })[];
    }
}

export enum CONTENT_TYPE {
    TEXT = 'TEXT',
    IMAGE = 'IMAGE',
    TICKET = 'TICKET'
}

export enum SENDER_TYPE {
    USER = 'USER',
    AI = 'AI'
}

export enum CONVERSATION_FEATURE {
    AI_CHAT = 'AI_CHAT',
}

export interface IConversation {
    readonly _id: string
    name: string
    featureId: string
    refPath: string,
    feature: CONVERSATION_FEATURE,
    createdAt: Date
}

export interface IMessages {
    message: string;
    sender: string | IUser;
    receiver: string | IUser;
    conversation: string | IConversation;
    senderType: SENDER_TYPE,
    contentType: CONTENT_TYPE,
    meta?: {
        tags?: {
            label: string
            id: string
            type: string
        }[]
        options?: (MenuItem & { description: string, type: string })[];
    }
    createdAt: Date

}
