import { Component, Input } from '@angular/core';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-custom-tooltip',
  standalone: true,
  imports: [TooltipModule],
  templateUrl: './custom-tooltip.component.html',
  styleUrl: './custom-tooltip.component.scss'
})
export class CustomTooltipComponent {
  @Input() public number: string = '';
  @Input() public content: string = '';

  constructor() { }

  ngOnInit(): void {
  }
}