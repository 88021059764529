import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ButtonModule } from "primeng/button";
import { DataViewModule } from 'primeng/dataview';
import { IContract } from "../../../../../models/contract-management.model";
import { ContractService } from "../../../../../services/contracts.service";
import { DateFormatterPipe } from "../../../../../../@shared/pipes/dateFormater.pipe";
import { FullNamePipe } from "../../../../../../@shared/pipes/fullName.pipe";
import { CardModule } from "primeng/card";
import { UserAvatarPipe } from "../../../../../../@shared/pipes/user-avatar-pipe";
import { PaginatorModule } from "primeng/paginator";
import { InputGroupModule } from "primeng/inputgroup";
import { InputTextModule } from "primeng/inputtext";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { IDocumentVersion, IUser } from "../../../../../../@core/models/model";
import { TabViewModule } from 'primeng/tabview';
import { IFile } from "../../../../settings/repository/repository.types";
import { VersionService } from "../../../../../services/version.service";
import { RepositoryService } from "../../../../../services/repository.service";

export enum DOCUMENT_MODEL {
    CONTRACT = 'Contract',
    REPOSITORY = 'File',
    VERSION = 'Version'
}


@Component({
    selector: 'app-compare-selection',
    standalone: true,
    templateUrl: './compare-selection.component.html',
    styleUrl: './compare-selection.component.scss',
    providers: [],
    imports: [
        ButtonModule,
        DataViewModule,
        DateFormatterPipe,
        FullNamePipe,
        CardModule,
        PaginatorModule,
        InputTextModule,
        InputGroupModule,
        InputGroupAddonModule,
        TabViewModule
    ]
})
export class CompareSelectionComponent implements OnInit {

    contracts: IContract[] = []
    versions: IDocumentVersion[] = []
    files: IFile[] = []
    sortField: string = 'name'
    sortOrder: number = 1
    loading: boolean = false
    searchValue: string = ''
    compareType = DOCUMENT_MODEL
    documentModel = DOCUMENT_MODEL
    defaultPaginatorValues = {
        first: 0,
        rows: 10
    }
    first: number = this.defaultPaginatorValues.first;
    rows: number = this.defaultPaginatorValues.rows;
    totalRecords: number = 0;
    tabType: DOCUMENT_MODEL = DOCUMENT_MODEL.CONTRACT

    userProfilePicture: { [key: string]: string } = {}
    private userAvatarPipe = new UserAvatarPipe();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { contractId: string, documentType: string, selectedDocuments: { _id: string, docModel: string }[] },
        private dialogRef: MatDialogRef<CompareSelectionComponent>,
        private contractService: ContractService,
        private versionService: VersionService,
        private repositoryService: RepositoryService
    ) {

    }

    ngOnInit(): void {
        this.getDocumentsByType();
        this.getVersions();
        this.getFiles();
    }

    getDocumentsByType() {
        const excludeDocs = this.data.selectedDocuments?.map(doc => doc._id) ?? [];
        excludeDocs.push(this.data.contractId);
        this.contractService.getDocumentsByType(this.data.documentType, { search: this.searchValue, skip: this.first, limit: this.rows, excludeDocs }).subscribe((res) => {
            if (res && res.success) {
                for (let contract of res.data?.contracts ?? []) {
                    if (contract.createdBy && !this.userProfilePicture[(contract.createdBy as IUser)._id]) {
                        this.userProfilePicture[(contract.createdBy as IUser)._id] = this.userAvatarPipe.transform(contract.createdBy)
                    }
                }
                this.contracts = res.data?.contracts ?? []
                this.totalRecords = res.data.total
                this.contracts.filter(contract => !(this.data.selectedDocuments || []).find(doc => doc._id == contract._id))
            }
        })
    }

    tabChange(event: any) {
        if (event.index == 0) {
            this.tabType = DOCUMENT_MODEL.CONTRACT
        } else if (event.index == 1) {
            this.tabType = DOCUMENT_MODEL.REPOSITORY
        } else if (event.index == 2) {
            this.tabType = DOCUMENT_MODEL.VERSION
        }
    }

    closeDialog() {
        this.dialogRef.close()
    }

    onPageChange(event: any) {
        this.first = event.first
        this.rows = event.rows
        if (this.tabType == this.documentModel.CONTRACT) {
            this.getDocumentsByType();
        } else if (this.tabType == this.documentModel.REPOSITORY) {
            this.getFiles();
        } else if (this.tabType == this.documentModel.VERSION) {
            this.getVersions();
        }
    }

    onSearch() {
        this.first = this.defaultPaginatorValues.first;
        this.rows = this.defaultPaginatorValues.rows;
        if (this.tabType == this.documentModel.CONTRACT) {
            this.getDocumentsByType();
        } else if (this.tabType == this.documentModel.REPOSITORY) {
            this.getFiles();
        } else if (this.tabType == this.documentModel.VERSION) {
            this.getVersions();
        }
    }

    selectContract(contract: IContract, compareType: DOCUMENT_MODEL) {
        this.dialogRef.close({ contract, compareType })
    }


    getVersions() {
        this.versionService.getVersions(this.data.contractId, { search: this.searchValue, skip: this.first, limit: this.rows }).subscribe(res => {
            if (res.success) {
                this.versions = res.data.versions
                this.totalRecords = res.data.total
                this.versions = this.versions.filter(version => !(this.data.selectedDocuments || []).find(doc => doc._id == version.file._id))
            }
        })
    }

    getFiles() {
        this.repositoryService.getDocTypeFiles(this.data.documentType, { search: this.searchValue, skip: this.first, limit: this.rows }).subscribe(res => {
            if (res.success) {
                this.files = res.data.files
                this.totalRecords = res.data.total
                this.files = this.files.filter(file => !(this.data.selectedDocuments || []).find(doc => doc._id == file._id))
            }
        })

    }



}