<div class="rounded-lg">
    <div class="flex justify-end">
        <i (click)="openModel()"
            class="pi pi-arrow-up-right-and-arrow-down-left-from-center absolute ml-2 text-xs cursor-pointer p-1 border rounded-lg bg-white shadow-md"></i>
    </div>

    <div class="overflow-x-auto" [innerHTML]="data.graph | sanitizeHtml"></div>
</div>


<p-dialog header="" [modal]="true" [(visible)]="OpenGraphDialog">
    <div [innerHTML]="data.graph | sanitizeHtml">
    </div>
</p-dialog>