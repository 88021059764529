import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, interval, switchMap } from 'rxjs';
import { BaseService } from '../../@core/services/base.service';
import { environment } from '../../../environments/environment.dev';
import { ChatMessage, IMessages } from '../components/chat/chat.types';
import { ChatSource } from '../components/chat/chat.component';
import { MenuItem } from 'primeng/api';
@Injectable({
    providedIn: 'root'
})
export class AiChatService extends BaseService {
    private url = environment.apiUrl + '/ai-chat'
    constructor(
        protected override http: HttpClient,
        protected ngZone: NgZone
    ) {
        super(http, ngZone);
    }

    fetchStreamedResponse(prompt: string, contractId: string, compareId: string, selectedConversation: string, compareDocumentIds?: { _id: string, docModel: string, name: string }[], docModel?: string, tags?: { label: string, id: string, type: string }[], options?: (MenuItem & { description: string; type: string })[]) {
        const compareDocuments = JSON.stringify(compareDocumentIds)
        const tags_stringified = JSON.stringify(tags)
        const options_stringified = JSON.stringify(options)
        return this.stream<string>(this.url + '/conversation', { prompt, contractId, compareId, compareDocuments, docModel, conversation: selectedConversation, tags: tags_stringified, options: options_stringified })
    }
    getResponse(prompt: string) {
        return this.get<string>(this.url, { prompt })
    }
    fecthSuggestions() {
        return this.get<string[]>(this.url + '/suggestions', {})
    }
    getResponseFromSuggestion(suggestion: string) {
        return this.get<string>(this.url + '/suggestion', { suggestion })
    }
    getHistory(userId: string) {
        return this.get<string[]>(this.url + '/history', { userId })

    }
    getAllConversations(featureId: string) {
        return this.get<string[]>(this.url + '/conversations', { featureId })
    }

    getConversation(conversation: string) {
        return this.get<IMessages[]>(this.url + '/conversation/' + conversation)
    }
    getPromptSuggestions(id: string, chatSource: ChatSource) {
        return this.post<string[]>(this.url + '/prompt-suggestions', { id, chatSource })
    }
    updateConversationName(conversation: string, name: string) {
        return this.put(this.url + '/conversation/' + conversation, { name })
    }
    deleteConversation(conversation: string) {
        return this.delete(this.url + '/conversation/' + conversation)
    }
}