import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ElementRef, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ChatMessage, CONTENT_TYPE, IConversation, SENDER_TYPE } from './chat.types';
import { AiChatService } from '../../services/aichat.service';
import { SVG_ICONS } from '../../constants/constant';
import { AiChatBubbleComponent } from './components/ai-chat-bubble/ai-chat-bubble.component';
import { SanitizeHtmlPipe } from '../../../@shared/pipes/sanitizeHtml.pipe';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToastService } from '../../../@core/services/toast.service';
import { RippleModule } from 'primeng/ripple';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { TruncatePipe } from "../../../@shared/pipes/truncate.pipe";
import { CategorizeByDatePipe } from '../../../@shared/pipes/categorizeByDate-chat.pipe';
import { ButtonModule } from 'primeng/button';
import { ScrollResetDirective } from '../../../@shared/directives/scroll-reset.directive';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { MenuItem } from 'primeng/api';
import { PanelMenuModule } from 'primeng/panelmenu';
import { LinkDocumentService } from '../../services/link-document.service';
import { ILinkDocument } from '../../../@core/models/model';
import { IKpi } from '../../models/settings.model';
import { MenuModule } from 'primeng/menu';


export enum ChatSource {
  CONTRACT = 'CONTRACT',
  COMPARE = 'COMPARE',
}

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AiChatBubbleComponent,
    SanitizeHtmlPipe,
    RippleModule,
    OverlayPanelModule,
    TooltipModule,
    TruncatePipe,
    ButtonModule,
    ScrollResetDirective,
    InputGroupModule,
    InputGroupAddonModule,
    PanelMenuModule,
    MenuModule
  ],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent implements OnInit, OnChanges {
  @ViewChild('editableContent', { static: false }) editableContent!: ElementRef;
  @ViewChild('suggestionsPanel', { static: false }) suggestionsPanel!: OverlayPanel;

  @Input({ required: true }) contractId!: string;
  @Input() compareId!: string;
  @Input() tabFocused: boolean = false;
  @Input() compareDocumentIds!: { _id: string, docModel: string, name: string }[];
  @Input() docModel!: string
  @Input() suggestions!: string[]
  @Input() chatSource!: ChatSource;
  @Input() showHistory: boolean = false;
  @Input() showTags: boolean = false;
  @Input() showOptions: boolean = false;
  @Input() kpis!: { _id: string, aspect: string, comparison: string, generateTable: boolean, generateGraph: boolean }[]
  public icons = SVG_ICONS;
  public maxPromptLength: number = 300
  public promptinput: string = ''
  public promptLength: number = 0
  public disabled: boolean = false
  public showLimitWarning: boolean = false;
  public unfilteredConversationsHistory!: IConversation[]
  public conversationsHistory!: IConversation[][]
  public selectedConversation!: IConversation['_id']
  public promptQuestions = [
    'What are all the clauses in this document?',
    'How far is the term of the contract?',
    'What is dispute clauses and dispute resolution in this document?',
    'What are payment terms in this document?'
  ]
  public inputPrompt: string = ''
  public prompt: string = ''
  public showSuggestions: boolean = true;
  public loading: boolean = false;
  public conversations: ChatMessage[] = [];
  public enableRename!: IConversation | null;
  public linkedDocuments: ILinkDocument[] = [];
  public showTagsPanel: boolean = false;
  public showOptionsPanel: boolean = false;
  public filteredLinkedDocuments: ILinkDocument[] = [];
  public identifiers: MenuItem[] = [];
  public options: (MenuItem & { description: string; type: string })[] = [
    {
      label: 'Table',
      icon: 'pi pi-table',
      description: 'Generate a table',
      type: 'TABLE',
      command: () => {

      }
    },
    {
      label: 'Graph',
      icon: 'pi pi-chart-bar',
      description: 'Generate a Graph',
      type: 'GRAPH',
      command: () => {

      }
    }
  ];
  public tags: { label: string, id: string, type: string }[] = []
  public selectedOptions: (MenuItem & { description: string; type: string })[] = []
  public filteredKpis: { _id: string, aspect: string, comparison: string, generateTable: boolean, generateGraph: boolean }[] = []


  @ViewChild('scrollBottom', { static: true })
  private scrollBottom!: ElementRef;
  constructor(
    private aiChatbotService: AiChatService,
    private linkedDocumentService: LinkDocumentService,
    private toast: ToastService,
    // private notificationService: NotificationService,
    @Optional() public dialogRef: MatDialogRef<ChatComponent>,
  ) { }
  ngOnInit(): void {
    this.getIdentifiers()
    this.getSuggestions();
    this.getConversations();
  }
  ngOnChanges(changes: SimpleChanges) {
    if ((changes['tabFocused'] && changes['tabFocused'].currentValue)) {
      this.getSuggestions();
    }
  }



  getIdentifiers() {
    switch (this.chatSource) {
      case ChatSource.CONTRACT:
        this.getLinkedDocuments();
        break;
      case ChatSource.COMPARE:
        this.getKpis();
        break;
    }
  }


  getKpis() {
    this.filteredKpis = this.kpis;
    this.identifiers = [{
      label: "KPIs",
      icon: 'pi pi-chart-bar',
      items: [...(this.kpis.map((kpi) => {
        return { label: kpi.aspect, command: () => this.selectTag(kpi.aspect, kpi._id ?? "", 'KPIS') }
      }))]
    }]
  }

  getLinkedDocuments() {
    this.linkedDocumentService.fetchLinkDocument(this.contractId).subscribe({
      next: res => {
        this.linkedDocuments = res.data;
        this.filteredLinkedDocuments = this.linkedDocuments;
        this.identifiers = [{
          label: "Linked Documents",
          icon: 'pi pi-link',
          items: [...(this.linkedDocuments.map((doc) => {
            return { label: doc.targetDocument.name, icon: 'pi pi-file', command: () => this.selectTag(doc.targetDocument.name, doc.targetDocument._id, 'LINKED_DOCS') }
          }))]
        }]
      }
    })
  }

  selectTag(label: string, tagId: string, type: string) {
    switch (type) {
      case 'LINKED_DOCS':
        this.prompt = this.prompt.replace('@', '');
        this.filteredLinkedDocuments = this.filteredLinkedDocuments.filter((doc) => doc.targetDocument._id !== tagId)
        this.identifiers = [{
          label: "Linked Documents",
          icon: 'pi pi-link',
          items: [...(this.filteredLinkedDocuments.map((doc) => {
            return { label: doc.targetDocument.name, icon: 'pi pi-file', command: () => this.selectTag(doc.targetDocument.name, doc.targetDocument._id, 'LINKED_DOCS') }
          }))]
        }]
        this.tags.push({ label, id: tagId, type })
        this.showTagsPanel = false;
        break;
      case 'KPIS':
        if (this.tags.length > 0) {
          this.toast.info('Only one KPI can be selected at a time');
          return;
        }
        this.prompt = this.prompt.replace('@', '');
        this.tags.push({ label, id: tagId, type })
        this.showTagsPanel = false;
        this.filteredKpis = this.kpis.filter((kpi) => kpi._id !== tagId)
        this.identifiers = [{
          label: "KPIs",
          icon: 'pi pi-chart-bar',
          items: [...(this.filteredKpis.map((kpi) => {
            return { label: kpi.aspect, icon: 'pi pi-chart-bar', command: () => this.selectTag(kpi.aspect, kpi._id ?? "", 'KPIS') }
          }))]
        }]
        break;
    }
  }

  selectOption(option: MenuItem & { description: string, type: string }) {
    this.selectedOptions = [];
    this.selectedOptions.push(option);
    this.showOptionsPanel = false;
  }


  removeOption(option: MenuItem & { description: string, type: string }) {
    this.selectedOptions = this.selectedOptions?.filter((item) => item.label !== option.label)
  }

  removeTag(tag: { label: string, id: string, type: string }) {
    switch (this.chatSource) {
      case ChatSource.CONTRACT:
        const linkedDoc = this.linkedDocuments.find((doc) => doc.targetDocument._id === tag.id)
        if (linkedDoc) this.filteredLinkedDocuments = [...this.filteredLinkedDocuments, linkedDoc]
        this.tags = this.tags.filter((item) => item.id !== tag.id)
        this.identifiers = [{
          label: "Linked Documents",
          icon: 'pi pi-link',
          items: [...(this.filteredLinkedDocuments.map((doc) => {
            return { label: doc.targetDocument.name, icon: 'pi pi-file', command: () => this.selectTag(doc.targetDocument.name, doc.targetDocument._id, 'LINKED_DOCS') }
          }))]
        }]
        break;

      case ChatSource.COMPARE:
        const kpi = this.kpis.find((kpi) => kpi._id === tag.id)
        if (kpi) this.filteredKpis = [...this.filteredKpis, kpi]
        this.tags = this.tags.filter((item) => item.id !== tag.id)
        this.identifiers = [{
          label: "KPIs",
          icon: 'pi pi-chart-bar',
          items: [...(this.filteredKpis.map((kpi) => {
            return { label: kpi.aspect, icon: 'pi pi-chart-bar', command: () => this.selectTag(kpi.aspect, kpi._id ?? "", 'KPIS') }
          }))]
        }]
        break;
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    const { key } = event;
    console.log(key)
    if (key === '@') {
      this.showTagsPanel = true;
    }
    else {
      this.showTagsPanel = false;
    }
  }

  getSuggestions() {
    this.aiChatbotService.getPromptSuggestions(this.chatSource == ChatSource.CONTRACT ? this.contractId : this.compareId, this.chatSource).subscribe({
      next: res => {
        this.promptQuestions = (res.data && res.data.length) ? res.data : (this.suggestions ?? this.promptQuestions);
      },
      error: _ => {
        this.promptQuestions = this.suggestions ?? this.promptQuestions
      }
    })
  }
  suggestionClick(suggestion: string) {
    this.showSuggestions = false;
    this.conversations.push({
      content: suggestion,
      contentType: CONTENT_TYPE.TEXT,
      timestamp: new Date().toISOString(),
      sender: SENDER_TYPE.USER,
      isLoading: false,
    });
    this.loading = true;
    this.conversations.push(
      {
        content: '',
        contentType: CONTENT_TYPE.TEXT,
        timestamp: new Date().toISOString(),
        sender: SENDER_TYPE.AI,
        isLoading: true,
      }
    );
    this.aiChatbotService.fetchStreamedResponse(suggestion, this.contractId, this.compareId, this.selectedConversation, this.compareDocumentIds, this.docModel).subscribe((res: any) => {
      if (res && res.data) {
        let data = JSON.parse(res.data)
        if (data.status.includes('[ERR]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].content = 'Something went wrong, please try again';
          this.conversations[this.conversations.length - 1].isLoading = false;
          this.loading = false;
          this.disabled = false;
        }
        else if (data.status.includes('[DONE]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].isLoading = false;
          this.loading = false;
          this.disabled = false;
        }
        else if (data.content.message.includes('[JIRA]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].contentType = CONTENT_TYPE.TICKET;
          this.conversations[this.conversations.length - 1].content = data.content.message;
          this.conversations[this.conversations.length - 1].isLoading = true;
          this.loading = true;
          this.disabled = true;
        }
        else {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].content = data.content.message;
          this.conversations[this.conversations.length - 1].isLoading = true;
          this.loading = true;
          this.disabled = true;
        }
      }
    })
  }
  getResponse(value: string, selected_tags: { label: string, id: string, type: string }[], selected_options: (MenuItem & { description: string; type: string })[]) {
    this.tags = [];
    this.selectedOptions = [];
    this.showSuggestions = false;
    this.conversations.push({ content: value, contentType: CONTENT_TYPE.TEXT, timestamp: new Date().toISOString(), sender: SENDER_TYPE.USER, isLoading: false, meta: { tags: selected_tags, options: selected_options } });
    this.loading = true;
    this.conversations.push({ content: '', contentType: CONTENT_TYPE.TEXT, timestamp: new Date().toISOString(), sender: SENDER_TYPE.AI, isLoading: true });
    if (this.conversations.length > 60) this.showLimitWarning = true;
    this.aiChatbotService.fetchStreamedResponse(value, this.contractId, this.compareId, this.selectedConversation, this.compareDocumentIds, this.docModel, selected_tags, selected_options).subscribe((res: any) => {
      if (res) {
        let data = res
        this.loading = true;
        if (data.status.includes('[DONE]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].content = data.content.message;
          this.conversations[this.conversations.length - 1].isLoading = false;
          this.loading = false;
          this.disabled = false;
          this.getConversations()
          this.selectedConversation = data.conversation;
        }
        else if (data.status.includes('[ERR]')) {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].content = 'Something went wrong, please try again';
          this.conversations[this.conversations.length - 1].isLoading = false;
          this.loading = false;
          this.disabled = false;
          this.tags = [];
        } else {
          this.scrollToBottom();
          this.conversations[this.conversations.length - 1].content = data.content.message;
          this.conversations[this.conversations.length - 1].isLoading = true;
          this.disabled = true;
        }
      }
    })
  }
  checkPromptLength() {
    this.promptLength = this.prompt.length;
  }
  scrollToBottom(): void {
    try {
      setTimeout(() => {
        this.scrollBottom.nativeElement.scrollTop = this.scrollBottom.nativeElement.scrollHeight;
      }, 100)
    } catch (err) {
    }
  }
  sendPrompt() {
    if (this.prompt.length > 0) {
      this.loading = true;
      this.promptinput = this.prompt;
      this.prompt = '';
      this.checkPromptLength();
      this.disabled = true;
      this.getResponse(this.promptinput, this.tags, this.selectedOptions);
      this.scrollToBottom();
    }
  }

  getConversations() {

    switch (this.chatSource) {
      case ChatSource.CONTRACT:
        this.aiChatbotService.getAllConversations(this.contractId).subscribe((res: any) => {
          if (res && res.data) {
            this.unfilteredConversationsHistory = res.data;
            this.conversationsHistory = new CategorizeByDatePipe().transform(res.data);
          }
        })
        break;
      case ChatSource.COMPARE:
        this.aiChatbotService.getAllConversations(this.compareId).subscribe((res: any) => {
          if (res && res.data) {
            this.unfilteredConversationsHistory = res.data;
            this.conversationsHistory = new CategorizeByDatePipe().transform(res.data);
          }
        })
        break;


    }

  }

  getMessages(conversation: string, historyPanel: any, event: Event) {
    if (historyPanel.overlayVisible) {
      historyPanel.toggle(event);
    }
    this.selectedConversation = conversation;
    this.aiChatbotService.getConversation(conversation).subscribe((res) => {
      if (res && res.data) {
        this.conversations = res.data.map((item) => {
          return {
            content: item.message,
            isLoading: false,
            contentType: item.contentType,
            sender: item.senderType,
            timestamp: new Date(item.createdAt).toISOString(),
            meta: item?.meta
          }
        })
        this.showSuggestions = false;
        this.scrollToBottom();

      }
    })
  }

  startNewConversation() {
    this.showSuggestions = true;
    this.conversations = [];
    this.selectedConversation = '';
  }

  updateConversationName(event: Event, conversation: IConversation) {
    event.preventDefault()
    const target = event.target as HTMLElement;
    const newName = target.textContent?.trim() || 'Untitled';
    target.innerHTML = newName
    if (newName && this.enableRename && conversation.name !== newName) {
      this.aiChatbotService.updateConversationName(conversation._id, newName).subscribe((res) => {
        if (res) {
          this.toast.success('Conversation name updated successfully');
          this.enableRename = null;
          this.getConversations();
        }
      })
    } else {
      this.enableRename = null;
    }
  }

  renameChatHistory(conversation: IConversation) {
    this.enableRename = conversation
  }

  blurSpan(event: Event) {
    const target = event.target as HTMLElement;
    target.blur();
  }
  searchConversations(event: Event) {
    if ((event.target as HTMLInputElement).value.toLowerCase() == "") {
      this.conversationsHistory = new CategorizeByDatePipe().transform(this.unfilteredConversationsHistory);
      return;
    }
    const filteredConversations = this.unfilteredConversationsHistory.filter((conversation) => conversation.name.toLowerCase().includes((event.target as HTMLInputElement).value.toLowerCase()));
    if (filteredConversations) {
      this.conversationsHistory = new CategorizeByDatePipe().transform(filteredConversations);
    } else {
      this.conversationsHistory = new CategorizeByDatePipe().transform(this.unfilteredConversationsHistory);
    }
  }

  deleteConversation(conversation: IConversation) {
    this.aiChatbotService.deleteConversation(conversation._id).subscribe((res) => {
      if (res) {
        this.toast.success('Conversation deleted successfully');
        if (this.selectedConversation === conversation._id) {
          this.startNewConversation();
        }
        this.getConversations();
      }
    })

  }


}
