<div>
    @if(navRequired){
    <div class="flex justify-between items-center p-2">
        <span class="text-lg font-bold">Compare History</span>
        <p-button icon="pi pi-plus-circle" styleClass="bg-arena-orange px-2 py-1  rounded-xl text-white font-semibold"
            type="submit" label="New" (onClick)="openCompareDialog()"></p-button>
    </div>
    <hr>
    }
    <div class="flex flex-col gap-2 pt-2">
        @if(loading){
        <div class="flex justify-center items-center h-full">
            <p-progressSpinner styleClass="my-4" [style]="{width: '30px', height: '30px'}"></p-progressSpinner>
        </div>
        }
        @else{
        <div class="my-3 items-center">
            <p-inputGroup class="flex items-center border rounded-xl px-2 bg-white" styleClass="items-center">
                <p-inputGroupAddon class="cursor-pointer">
                    <i class="pi pi-search text-arena-orange text-lg"></i>
                </p-inputGroupAddon>
                <input pInputText type="text" placeholder="Search a comparison" (input)="searchCompares($event)"
                    class="flex-1 outline-none border-none rounded-xl p-2" />
            </p-inputGroup>
        </div>
        <p-accordion class="w-full" [activeIndex]="0" styleClass="border-t">
            @for (item of filteredHistory; track $index) {
            <p-accordionTab styleClass="border-b">
                <ng-template pTemplate="header">
                    <span class="flex w-full items-center justify-between">
                        <div class="flex flex-col" (click)="$event.stopPropagation(); openComparison(item._id)">
                            <span class="text-lg font-semibold">{{item.name}}
                                <i class="pi pi-external-link small-icon"></i>
                            </span>
                            <span class="text-xs font-normal text-gray-400">{{item.updatedAt |
                                date: 'medium'}}</span>
                        </div>
                        <!-- <p-button label="View"
                            class="border-orange-500 px-2 py-1  rounded-xl text-bg-arena-orange font-semibold"
                            type="button"></p-button> -->

                    </span>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="rounded-xl">
                        <div class="flex flex-col justify-between p-1">
                            <div class="justify-between grid grid-cols-2 gap-2 p-2 ">
                                @for (compareDoc of item.compareContracts; track $index) {
                                <div class="border p-2 rounded-xl">
                                    <div class="flex gap-2 items-center justify-between">
                                        <div class="flex gap-1 items-center">

                                            <i class="pi pi-file"></i>
                                            <span class="font-medium">{{compareDoc.doc.name | truncate:10}}</span>
                                        </div>
                                        <div (click)="openDocument(compareDoc._id ,compareDoc.docModel)">
                                            <i class="pi pi-external-link small-icon"></i>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                            <div class="flex justify-between items-center">
                                <div class="flex gap-2 items-center">
                                    <img [src]="item.createdBy | userAvatar" alt="User Avatar"
                                        class="w-6 h-6 rounded-full" />
                                    <div class="flex flex-col">
                                        <span class="text-xs font-medium">{{item.createdBy | fullName }}</span>
                                        <span class="text-xs font-medium">{{item.createdBy.email}}</span>
                                    </div>
                                </div>
                                <div class="items-center">
                                    <p-button text="true" icon="pi pi-trash" (onClick)="deleteComparison(item._id)"
                                        class="px-2 py-1  rounded-xl text-red-500 font-semibold"
                                        type="button"></p-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-accordionTab>
            }
        </p-accordion>
        }
        @if((filteredHistory.length ==0 || history.length === 0) && !loading) {
        <div class="flex justify-center items-center h-32">
            <span class="text-lg font-semibold text-gray-500">No history found</span>
        </div>
        }
    </div>

</div>