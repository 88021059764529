import { Component, Input } from '@angular/core';
import { SanitizeHtmlPipe } from "../../../pipes/sanitizeHtml.pipe";
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-custom-graph-model',
  standalone: true,
  imports: [
    SanitizeHtmlPipe,
    DialogModule
  ],
  templateUrl: './custom-graph-model.component.html',
  styleUrl: './custom-graph-model.component.scss'
})
export class CustomGraphModelComponent {

  @Input() public data!: { graph: string, graphType: string };
  public OpenGraphDialog: boolean = false;

  constructor() { }

  ngOnInit() {
    console.log('CustomGraphModelComponent', this.data);
  }

  openModel() {
    this.OpenGraphDialog = !this.OpenGraphDialog;
  }

}
