<p-confirmDialog #cd>
    <ng-template pTemplate="headless" let-message>
        <div class="flex flex-col align-items-center p-5 surface-overlay border-round w-[30rem]">
            <span class="font-bold text-2xl block mb-2 mt-4">
                {{ message.header }}
            </span>

            <p class="mb-0" [innerHTML]="message.message"></p>

            <div class="flex justify-end items-end float-left gap-2 mt-4">
                <p-button label="Yes" (onClick)="cd.accept()"
                    styleClass="bg-white rounded-xl border border-green-500 text-green-500 hover:bg-green-100 hover:text-green-500 px-4 py-2 "
                    icon="pi pi-check"></p-button>

                <p-button label="No" (onClick)="cd.hide()"
                    styleClass="bg-white rounded-xl border border-red-500 text-red-500 hover:bg-red-100 hover:text-red-500 px-4 py-2 "
                    icon="pi pi-times"></p-button>
            </div>
        </div>
    </ng-template>
</p-confirmDialog>

@if(fetchingRole){
<div class="flex justify-center items-center  h-screen">
    <div class="loader"></div>
</div>
}@else {
@if(permissions.restricted){
<!-- Cannot view contract -->
<div>
    <div class="flex flex-col items-center justify-center h-screen">
        <div class="flex flex-col items-center">
            <img src="/images/access_denied.png" alt="404">
            <h1 class="text-2xl font-semibold text-center">You do not have permission to view this document</h1>
            <p class="text-gray-500 text-center">Please contact the owner of the document for access</p>
        </div>
    </div>
</div>
}@else {
@if(contract){
<app-download-doc [contract]="contract" [visibility]="openDownloadContract"
    (visibilityChange)="openDownloadContract=$event"></app-download-doc>
<section class="aspect-auto h-screen flex flex-col overflow-x-hidden">
    <div class="flex bg-white justify-between items-center p-2 pr-5 drop-shadow relative z-10">
        <div class="flex items-center" #left>
            @if(!isAnonymousUser){
            <i class="pi pi-angle-left cursor-pointer m-2 text-primary" (click)="goBack()"></i>
            }
            <div>
                <div class="flex items-center">
                    <span #editableContent scrollReset
                        class="text-xl mx-3 max-w-[400px] whitespace-nowrap overflow-x-auto inline-block no-scrollbar"
                        [attr.contenteditable]="isOwner || (isSameOrganization && permissions.canEdit)"
                        (keydown)="$event.key === 'Enter' ? blurSpan($event) : null"
                        (blur)="updateContractName($event)">
                        {{contract.name}}
                    </span>
                    <div class="flex items-center gap-2">
                        <app-badge [contract]="contract"></app-badge>
                        <div class="m-2 rounded-md p-1 mt-[10px] font-medium text-xs bg-gray-100">
                            {{contract.linkedDocType.name}}
                        </div>
                        <i class="pi pi-upload ml-1 cursor-pointer"
                            *ngIf="isOwner || (isSameOrganization && permissions.canEdit)"
                            (click)="this.openCreateDialog()"></i>

                    </div>
                </div>
            </div>
        </div>

        <div #right class="flex gap-3 items-center">
            <app-badge [permission]="currentRole"></app-badge>
            <i class="pi pi-refresh last-edit" [ngClass]="{'pi-spin': loading}" style="cursor: pointer;"
                (click)="getLastModified()">
            </i>
            <p class="last-edit">Last Edit on {{docUpdatedAt| date:'mediumDate'}} at {{docUpdatedAt |
                date:'shortTime'}}</p>
            @if (connectedUsers$ && (connectedUsers$ | async)!.length) {
            <p-avatarGroup styleClass="rounded-full p-2 pb-0">
                @for (user of (connectedUsers$ | async)?.slice(0,4); track $index) {
                <p-avatar [image]="user | userAvatar" shape='circle' [pTooltip]="user | fullName"
                    tooltipPosition="bottom"></p-avatar>
                }
                @if ((connectedUsers$ | async)!.length > 4) {
                <p-avatar [label]="'+' + (connectedUsers$ | async)!.length" shape="circle"
                    [style]="{ 'background-color': '#FCFCFD', color: '#1C1C1C' }" />
                }
            </p-avatarGroup>
            }
            <!-- @if(this.isOwner || (isSameOrganization && this.permissions.canEdit)){
            <p-button styleClass="border border-orange-500 py-1 px-2 cursor-pointer rounded-lg text-orange-500"
                [disabled]="versionLoading" (onClick)="saveVersion()" icon="pi pi-check-circle" label="Save Version" />
            } -->
            @if(this.isOwner || (isSameOrganization && this.permissions.canEdit)){
            <app-share-doc [contract]="contract" />
            }
            @if(this.contract.status != CONTRACT_STATUS.SIGNATURE_COMPLETED){
            <!-- TODO - revert the disable -->
            <p-button *ngIf="isOwner || isEditor" [ngClass]="labelText === 'Publish' ? 'publish' : 'approval'"
                styleClass="border-2 py-1 px-2" [rounded]="true" [disabled]="actionButton" (onClick)="action()">
                <img [src]="getIconPath()" class="mr-1" alt="Icon" style="width: 20px; height: 20px;" />
                {{labelText}}
            </p-button>
            }
            <p-tieredMenu #optionsMenu styleClass="p-3 border rounded-lg" [style]="{'width':'12rem'}" [model]="options"
                [popup]="true" appendTo="body" />
            <p-button (onClick)="optionsMenu.toggle($event)" class="border rounded-lg py-[6px]" icon="pi pi-ellipsis-v"
                [text]="true" />
        </div>
    </div>

    <div class="flex-1">
        @if(!isSameOrganization) {
        <div class="w-full h-full overflow-hidden">
            <!-- <app-contract-editor [contractId]="contract._id" class="h-full block overflow-hidden"
                    [permissions]="permissions" [isOwner]="isOwner" [approvalConf]="approvalConf"
                    (onApprovalChange)="onApprovalChange($event)" [markdownContent]="markdownContent" [comments]="comments"
                    #contractEditor></app-contract-editor> -->
            <div [ngClass]="bottomBarVisible ? 'h-[95%]' : 'h-[100%]'">
                <app-only-office-doc-editor [contractId]="contract._id" [user]="currentUser!" [configData]="config">
                </app-only-office-doc-editor>
            </div>
            <!-- <app-sharepoint></app-sharepoint> -->
            @if(bottomBarVisible){
            <div class="h-[5%] flex flex-1 w-full  items-center">
                @if(currentApprover?.email === currentUser?.email){
                @if(currentApprover?.status == CONTRACT_APPROVAL_STATUS.PENDING){
                <div class="flex flex-row gap-x-6 justify-center items-center w-full">
                    <div class="flex flex-row ">
                        <div class="font-bont text-sm">
                            <span class="font-normal">Please approve this document</span>
                        </div>
                    </div>
                    <div class="flex gap-x-3">
                        <p-button label="Approve" (onClick)="approveDoc('Approve')"
                            styleClass="bg-white text-sm rounded-md border border-green-500 text-green-500 hover:bg-green-500 hover:text-white px-1.5 "
                            icon="pi pi-check"></p-button>

                        <p-button label="Reject" (onClick)="approveDoc('Reject')"
                            styleClass="bg-white text-sm rounded-md border border-red-500 text-red-500 hover:bg-red-500 hover:text-white px-1.5"
                            icon="pi pi-times"></p-button>
                    </div>
                </div>
                }

                @if(currentApprover?.status == CONTRACT_APPROVAL_STATUS.APPROVED && !isOwner){
                <div class="flex flex-row gap-x-6 justify-center items-center w-full">
                    <div class="flex flex-row ">
                        <div class="font-bont text-sm">
                            You have <span class="text-green-600">approved</span> this Document
                        </div>
                    </div>
                </div>
                }
                @if(currentApprover?.status == CONTRACT_APPROVAL_STATUS.REJECTED){
                <div class="flex flex-row gap-x-6 justify-center items-center w-full">
                    <div class="flex flex-row ">
                        <div class="font-bont text-sm">
                            You have <span class="text-red-600">rejected</span> this Document
                        </div>
                    </div>
                </div>
                }
                }
            </div>
            }
        </div>
        } @else {
        <p-splitter styleClass="w-full h-full" [panelSizes]="panelSizes" [minSizes]="[60, 25]"
            (onResizeEnd)="onResizePanel($event)">
            <ng-template pTemplate>
                <div class="w-full overflow-hidden">
                    <!-- <app-contract-editor [contractId]="contract._id" class="h-full block overflow-hidden"
                            [permissions]="permissions" [isOwner]="isOwner" [approvalConf]="approvalConf"
                            (onApprovalChange)="onApprovalChange($event)" [markdownContent]="markdownContent" [comments]="comments"
                            #contractEditor></app-contract-editor> -->
                    <div [ngClass]="bottomBarVisible ? 'h-[95%]' : 'h-[100%]'">
                        <app-only-office-doc-editor [contractId]="contract._id" [user]="currentUser!"
                            [configData]="config" #onlyOfficeDocEditor>
                        </app-only-office-doc-editor>
                    </div>
                    <!-- <app-sharepoint></app-sharepoint> -->
                    @if(bottomBarVisible){
                    <div class="h-[5%] flex flex-1 w-full  items-center">
                        @if(currentApprover?.email === currentUser?.email){
                        @if(currentApprover?.status == CONTRACT_APPROVAL_STATUS.PENDING){
                        <div class="flex flex-row gap-x-6 justify-center items-center w-full">
                            <div class="flex flex-row ">
                                <div class="font-bont text-sm">
                                    <span class="font-normal">Please approve this document</span>
                                </div>
                            </div>
                            <div class="flex gap-x-3">
                                <p-button label="Approve" (onClick)="approveDoc('Approve')"
                                    styleClass="bg-white text-sm rounded-md border border-green-500 text-green-500 hover:bg-green-500 hover:text-white px-1.5 "
                                    icon="pi pi-check"></p-button>

                                <p-button label="Reject" (onClick)="approveDoc('Reject')"
                                    styleClass="bg-white text-sm rounded-md border border-red-500 text-red-500 hover:bg-red-500 hover:text-white px-1.5"
                                    icon="pi pi-times"></p-button>
                            </div>
                        </div>
                        }

                        @if(currentApprover?.status == CONTRACT_APPROVAL_STATUS.APPROVED && !isOwner){
                        <div class="flex flex-row gap-x-6 justify-center items-center w-full">
                            <div class="flex flex-row ">
                                <div class="font-bont text-sm">
                                    You have <span class="text-green-600">approved</span> this Document
                                </div>
                            </div>
                        </div>
                        }
                        @if(currentApprover?.status == CONTRACT_APPROVAL_STATUS.REJECTED){
                        <div class="flex flex-row gap-x-6 justify-center items-center w-full">
                            <div class="flex flex-row ">
                                <div class="font-bont text-sm">
                                    You have <span class="text-red-600">rejected</span> this Document
                                </div>
                            </div>
                        </div>
                        }
                        }
                    </div>
                    }
                </div>
            </ng-template>
            <ng-template pTemplate>
                <div class="w-full col-span-3 bg-white border-l-2 p-2 overflow-[100%] comments">
                    <p-tabView [(activeIndex)]="commentService.tabActiveIndex" class="inherit"
                        styleClass=" overflow-hidden">
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <div class="flex flex-row gap-2 items-center">
                                    <span class="pi pi-chart-line"></span>
                                    <span class="text-lg font-semibold">Insights</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div class="h-[100%] ">
                                    @if (contractId) {
                                    <app-contract-insights class="block h-[100%] w-full" [contractId]="contractId"
                                        [documentType]="contract.linkedDocType._id"></app-contract-insights>
                                    }
                                </div>
                            </ng-template>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <div class="flex flex-row gap-2 justify-between items-center">
                                    <span class="pi pi-check-square"></span>
                                    <span class="text-lg font-semibold">Approvers</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div class="flex flex-col p-2 h-full">
                                    <div class="flex flex-row justify-between">
                                        <div class="flex items-center">
                                            <span class="text-lg font-semibold">Approvers</span>
                                            @if(approvalConf && (approvalConf.status ==
                                            CONTRACT_APPROVAL_STATUS.SKIPPED)){
                                            <span
                                                class="text-xs font-semibold text-green-500 ml-2 bg-green-100 rounded-md px-2 py-0.5">Skipped</span>
                                            }
                                        </div>
                                        @if(currentRole === CONTRACT_USER_ROLE.OWNER || CONTRACT_USER_ROLE.EDITOR){
                                        <p-button *ngIf="permissions.canEdit" styleClass="border-2 py-1 px-2 rounded-xl"
                                            label="Reset" icon="pi pi-refresh"
                                            [disabled]="!(approvalConf && (approvalConf.status == CONTRACT_APPROVAL_STATUS.WAITING))"
                                            (onClick)="resetApprovalFlow()" />
                                        }
                                    </div>

                                    <div class="flex-grow overflow-hidden mt-4">
                                        <div class="h-full overflow-auto no-scrollbar">
                                            @if(approvalConf && approvalConf.approvers.length > 0){
                                            <app-approval-workflow [approvers]="allApprovers"
                                                [canEdit]="!permissions.canEdit && !isOwner"
                                                (deleteApproverEvent)="approvalChange()"
                                                [flowType]="flowType.APPROVAL"></app-approval-workflow>
                                            }
                                            @else {
                                            <div class="flex justify-center items-center">
                                                <span class="text-gray-400">No approvers added</span>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                    @if(isOwner || isEditor){
                                    <div class="flex flex-row justify-between p-2">
                                        <p-button
                                            [label]="approvalConf.approvers.length ==0 ? 'Add Approver' : 'Edit Approvers'"
                                            [icon]="approvalConf.approvers.length ==0 ? 'pi pi-plus' : 'pi pi-pencil'"
                                            [disabled]="!(contract.status == CONTRACT_STATUS.READY_FOR_APPROVAL || contract.status == CONTRACT_STATUS.DRAFT)"
                                            styleClass="w-100 bg-white p-1 px-3 rounded-xl border-2"
                                            [severity]="'primary'" [rounded]="true"
                                            (onClick)="toggleApproveDialog()"></p-button>
                                        <p-button styleClass="w-100 bg-white p-1 px-3 rounded-xl border-2"
                                            [label]="approvalConf.status == CONTRACT_APPROVAL_STATUS.SKIPPED ? 'Skipped' : 'Skip Approval'"
                                            icon="pi pi-forward" [rounded]="true" (onClick)="skipApprovalFlow()"
                                            [disabled]="!(contract.status == CONTRACT_STATUS.READY_FOR_APPROVAL || contract.status == CONTRACT_STATUS.PENDING_APPROVAL || contract.status == CONTRACT_STATUS.DRAFT)"></p-button>
                                    </div>
                                    }
                                </div>
                            </ng-template>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <div class="flex flex-row gap-2 justify-between items-center">
                                    <span class="pi pi-user-edit"></span>
                                    <span class="text-lg font-semibold">Signatories</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div class="flex flex-col h-full p-2">
                                    <div class="flex flex-row justify-between">
                                        <div class="flex items-center">
                                            <span class="text-lg font-semibold">Signatories</span>
                                        </div>

                                        <div class="flex">
                                            @if(signatoryType == CONTRACT_SIGNATORY_TYPE.ADOBE_SIGN){
                                            <div class="flex items-center mr-2" [pTooltip]="tooltipContentAdobe"
                                                tooltipPosition="top">
                                                <img src="/images/logos/adobe.png" height="24" width="24" alt="icon">
                                            </div>
                                            }@else if(signatoryType == CONTRACT_SIGNATORY_TYPE.DOCU_SIGN){
                                            <div class="flex items-center mr-2" [pTooltip]="tooltipContentDocu"
                                                tooltipPosition="top">
                                                <img src="/images/logos/docusign.png" height="24" width="24" alt="icon">
                                            </div>
                                            }
                                            @if(this.contract.status != CONTRACT_STATUS.SIGNATURE_COMPLETED){
                                            <p-button *ngIf="permissions.canEdit"
                                                styleClass="border-2 py-1 px-2 rounded-xl" label="Reset"
                                                icon="pi pi-refresh"
                                                [disabled]="this.contract.status != CONTRACT_STATUS.SIGNATURE_IN_PROGRESS"
                                                (onClick)="resetSignatoryFlow()" />
                                            }
                                        </div>
                                    </div>

                                    <div class="flex-grow overflow-hidden mt-4">
                                        <div class="h-full overflow-auto  no-scrollbar">
                                            @if(signatoryConf && signatoryConf.signatories.length > 0){
                                            <app-approval-workflow [signatories]="allSignatories"
                                                [flowType]="flowType.SIGNATORY"
                                                (deleteSignatoryEvent)="signatoryChange($event)"
                                                (downloadSignatoryEvent)="downloadSignedDocument($event)"
                                                [contractStatus]="contract.status"></app-approval-workflow>
                                            }
                                            @else {
                                            <div class="flex justify-center items-center">
                                                <span class="text-gray-400">No Signatories added</span>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                    @if(permissions.canEdit){
                                    <div class="flex flex-row justify-center items-center p-2">
                                        <p-button
                                            [label]="(signatoryConf && signatoryConf.signatories.length == 0)? 'Add Signatories' : 'Edit signatories'"
                                            [icon]="(signatoryConf && signatoryConf.signatories.length ==0) ? 'pi pi-plus' : 'pi pi-pencil'"
                                            styleClass="w-100 bg-white p-1 px-3 rounded-xl border-2"
                                            [severity]="'primary'" [rounded]="true" [disabled]="disableAddSignatories"
                                            (onClick)="toggleSignatoryDialog()"></p-button>
                                    </div>
                                    }
                                </div>
                            </ng-template>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <div class="flex flex-row gap-2 justify-between items-center">
                                    <span class="pi pi-comments"></span>
                                    <span class="text-lg font-semibold text-nowrap">Chat with AI</span>
                                </div>
                            </ng-template>
                            <div class="chat-container border border-gray-200 rounded-lg">
                                <app-chat [contractId]="contractId" [showHistory]="true"
                                    [chatSource]="ChatSource.CONTRACT" [showTags]="true" [showOptions]="true"
                                    [tabFocused]="commentService.tabActiveIndex === 3"></app-chat>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel>
                            <ng-template pTemplate="header">
                                <div class="flex flex-row gap-2 justify-between items-center">
                                    <span class="pi pi-link"></span>
                                    <span class="text-lg font-semibold flex whitespace-nowrap">Linked Files</span>
                                </div>
                            </ng-template>
                            <app-link-file class="block h-full w-full" [document]="contract"></app-link-file>
                        </p-tabPanel>
                    </p-tabView>
                </div>
            </ng-template>
        </p-splitter>
        }

    </div>
</section>
}
}
}

<ng-template #tooltipContentAdobe>
    <div class="flex align-items-center">
        <span> Adobe sign </span>
    </div>
</ng-template>

<ng-template #tooltipContentDocu>
    <div class="flex align-items-center">
        <span> Docusign </span>
    </div>
</ng-template>

<p-dialog [visible]="approversDialog" styleClass="shadow-lg border border-gray-300 p-2 rounded-xl " [modal]="true"
    [closeOnEscape]="true" [closable]="false" [style]="{'width':'600px' ,'height' : '500px'}"
    (onHide)="onCloseApproverDialog()">
    <ng-template pTemplate="header">
        <div class="flex-1 flex justify-between">
            <div class="font-bold text-md">Add user to the approval level</div>
            <div class="flex items-center">
                <i class="pi pi-times text-lg cursor-pointer" (click)="toggleApproveDialog()"></i>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-col flex-1 h-full">
            <p-iconField iconPosition="left">
                <div class="flex w-full items-center space-x-2">
                    <p-autoComplete [suggestions]="autoCompleteUsers" (completeMethod)="autoCompleteUsersMethod($event)"
                        [(ngModel)]="autoCompleteValue" field="fullName" [dropdown]="false" [showEmptyMessage]="false"
                        placeholder="Add approvers by name or email" [style]="{'width':'100%','height': '100%'}"
                        [inputStyle]="{'width':'100%', 'height': '100%','padding-left' : '0.5rem'}"
                        class="flex-grow rounded-lg border border-gray-300 bg-red-500 outline-orange-400 h-10" size="40"
                        (onSelect)="addInternalApprover($event)">
                        <ng-template let-user pTemplate="item">
                            <div class="custom-item flex items-center p-2">
                                <div class="user-avatar">
                                    <img [src]="user | userAvatar" class="w-8 h-8 rounded-full" />
                                </div>
                                <div class="ml-3">
                                    <span class="block font-semibold">{{ user.fullName }}</span>
                                    <span class="block text-sm text-gray-500">{{ user.email }}</span>
                                </div>
                            </div>
                        </ng-template>
                    </p-autoComplete>
                    <p-button styleClass="bg-arena-orange rounded-lg h-10 text-white px-4"
                        (onClick)="addExternalApprover()" label="Add" [disabled]="validApproverEmail()"></p-button>
                </div>
            </p-iconField>



            <div class="flex flex-col mt-2 flex-1 h-full overflow-y-auto no-scrollbar overflow-hidden">
                @for (item of approvers; track $index) {
                <div pDraggable pDroppable
                    class="flex items-center space-x-2 text-black p-2 rounded-xl transition-all ease-in-out"
                    (onDragStart)="dragStart($index)" (onDrop)="drop($index)">
                    <i class="pi pi-bars cursor-move text-gray-400 mr-2"></i>

                    <!-- User Avatar -->
                    <div class="relative inline-block">
                        <img [src]="item.user | userAvatar" alt="User Avatar" class="w-10 h-10 rounded-full" />
                        @if(item.type == USER_TYPE.EXTERNAL){
                        <span class="absolute bottom-0 right-0 w-6 h-6 ">
                            <img [src]="'/images/external-badge.png'" alt="External Badge" class="rounded-full"
                                [pTooltip]="'External user'" />
                        </span>
                        }
                    </div>
                    <!-- User Info -->
                    <div class="flex flex-col text-sm w-[50%]">
                        @if (editApprover[$index]) {
                        @if (item.type == USER_TYPE.EXTERNAL) {
                        <input [(ngModel)]="item.name" class="border bg-transparent rounded-md mb-0.5 p-0.5 pl-1.5" />
                        } @else {
                        <span class="p-0.5 pl-1.5">{{ item.name }}</span>
                        }
                        <input [(ngModel)]="item.email" class="border bg-transparent rounded-md p-0.5 pl-1.5" />
                        } @else {
                        <span class="p-0.5 pl-1.5">{{ item.name }}</span>
                        <span class="text-gray-500 p-0.5 pl-1.5">{{ item.email }}</span>
                        }
                    </div>
                    <!-- Level and Actions -->
                    <div class="flex-1 flex justify-end items-center">
                        <div class="bg-sky-100 border-sky-200 border px-3 rounded-md text-sky-600">
                            Level {{$index + 1}}
                        </div>
                        <div class="h-full">
                            <i class="pi border py-3 px-2.5 rounded-xl ml-2 cursor-pointer"
                                (click)="editApproverFunc($index)"
                                [ngClass]="editApprover[$index] ? 'pi-check' : 'pi-pencil'"></i>
                            <i class="pi pi-trash border py-3 px-2.5 rounded-xl text-red-600 ml-2 cursor-pointer"
                                (click)="removeApprover($index,'approver')"></i>
                        </div>
                    </div>
                </div>
                }
            </div>

        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="flex justify-end">
            <p-button styleClass="border px-3 py-2 rounded-lg w-full text-black" label="Cancel"
                (onClick)="approversDialog=false"></p-button>
            <p-button styleClass="ml-2 bg-arena-orange px-3 py-2 rounded-lg w-full text-white"
                (onClick)="saveApprovalWorkflow()" label="Save"></p-button>
        </div>
    </ng-template>
</p-dialog>

<p-dialog [visible]="signatoryDialog" styleClass="shadow-lg border border-gray-300 p-2 rounded-xl " [modal]="true"
    [closeOnEscape]="true" [closable]="false" [style]="{'width':'600px' ,'height' : '500px'}"
    (onHide)="onCloseSignatoryDialog()">
    <ng-template pTemplate="header">
        <div class="flex-1 flex justify-between">
            <div class="font-bold text-md">Add user to the signatory level</div>
            <div class="flex items-center">
                <i class="pi pi-times text-lg cursor-pointer" (click)="toggleSignatoryDialog()"></i>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-col flex-1 h-full">
            <p-iconField iconPosition="left">
                <div class="flex w-full items-center space-x-2">
                    <p-autoComplete [suggestions]="autoCompleteUsers" (completeMethod)="autoCompleteUsersMethod($event)"
                        [(ngModel)]="autoCompleteValue" field="fullName" [dropdown]="false" [showEmptyMessage]="false"
                        placeholder="Add signatories by name or email" [style]="{'width':'100%','height': '100%'}"
                        [inputStyle]="{'width':'100%', 'height': '100%','padding-left' : '0.5rem'}"
                        class="flex-grow rounded-lg border border-gray-300 bg-red-500 outline-orange-400 h-10" size="40"
                        (onSelect)="addSignatory($event)">
                        <ng-template let-user pTemplate="item">
                            <div class="custom-item flex items-center p-2">
                                <div class="user-avatar">
                                    <img [src]="user | userAvatar" class="w-8 h-8 rounded-full" />
                                </div>
                                <div class="ml-3">
                                    <span class="block font-semibold">{{ user.fullName }}</span>
                                    <span class="block text-sm text-gray-500">{{ user.email }}</span>
                                </div>
                            </div>
                        </ng-template>
                    </p-autoComplete>
                    <p-button styleClass="bg-arena-orange rounded-lg h-10 text-white px-4"
                        (onClick)="addExternalSignatory()" label="Add" [disabled]="validApproverEmail()"></p-button>
                </div>
            </p-iconField>



            <div class="flex flex-col mt-2 flex-1 h-full overflow-y-auto no-scrollbar overflow-hidden">
                @for (item of signatories; track $index) {
                <div pDraggable pDroppable [dragHandle]="'.handle'"
                    class="flex items-center space-x-2 text-black p-2 rounded-xl transition-all ease-in-out"
                    (onDragStart)="dragStart($index)" (drop)="dropSignatories($index)">
                    <i class="pi pi-bars cursor-move handle text-gray-400 mr-2"></i>
                    <div class="relative inline-block">
                        <img [src]="item.user | userAvatar" alt="User Avatar" class="w-10 h-10 rounded-full" />
                        @if(item.type == USER_TYPE.EXTERNAL){
                        <span class="absolute bottom-0 right-0 w-6 h-6 ">
                            <img [src]="'/images/external-badge.png'" alt="External Badge" class="rounded-full"
                                [pTooltip]="'External user'" />
                        </span>
                        }
                    </div>
                    <div class="flex flex-col text-sm w-[50%]">
                        @if (editApprover[$index]) {
                        <input [(ngModel)]="item.name" class="border bg-transparent rounded-md mb-0.5 p-0.5 pl-1.5" />
                        <input [(ngModel)]="item.email" class="border bg-transparent rounded-md p-0.5 pl-1.5" />
                        }@else {
                        <span class="p-0.5 pl-1.5">{{item.name}}</span>
                        <span class="text-gray-500 p-0.5 pl-1.5">{{item.email}}</span>
                        }
                    </div>
                    <div class="flex-1 flex justify-end items-center ">
                        <div class="bg-sky-100 border-sky-200 border px-3 rounded-md text-sky-600">Level {{$index+1}}
                        </div>
                        <div class=" h-full">
                            <i class="pi  border py-3 px-2.5 rounded-xl ml-2 cursor-pointer"
                                (click)="editApproverFunc($index)"
                                [ngClass]="editApprover[$index] ? 'pi-check' : 'pi-pencil'"></i>
                            <i class="pi pi-trash border py-3 px-2.5 rounded-xl text-red-600 ml-2 cursor-pointer"
                                (click)="removeApprover($index,'signatory')"></i>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="flex justify-end">
            <p-button styleClass="border px-3 py-2 rounded-lg w-full text-black" label="Cancel"
                (onClick)="signatoryDialog=false"></p-button>
            <p-button styleClass="ml-2 bg-arena-orange px-3 py-2 rounded-lg w-full text-white"
                (onClick)="updateSignatories()" label="Save"></p-button>
        </div>
    </ng-template>
</p-dialog>


<p-dialog [visible]="showVersionsHistoryDialog" styleClass="shadow-lg border border-gray-300 p-2 rounded-xl "
    [modal]="true" [closeOnEscape]="true" [draggable]="false" [closable]="false"
    [style]="{'width':'600px' ,'height' : '500px'}" (onHide)="onCloseApproverDialog()">

    <ng-template pTemplate="header">
        <div class="flex-1 flex justify-between">
            <div class="font-bold text-md">Version History</div>
            <div class="flex items-center">
                <i class="pi pi-times text-lg cursor-pointer" (click)="toggleVersionsHistoryDialog()"></i>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-col flex-1 h-full">
            @if (!versions || versions.length ==0) {
            <div class="flex justify-center items-center h-full">
                <span class="text-gray-400">No versions available</span>
            </div>
            }
            <div class="flex flex-col mt-2 max-h-full  overflow-y-auto  overflow-hidden">
                <div class="timeline">
                    @for (item of versions; track $index) {
                    <div class="timeline-item flex space-x-4  group">
                        <div class="flex flex-col items-center ">
                            <span class="timeline-dot w-4 h-4 rounded-full bg-orange-500 border border-gray-300"></span>
                            <div class="timeline-line flex-1 w-px bg-gray-300"></div>
                        </div>
                        <div
                            class="w-full flex flex-row items-center justify-between bg-gray-50 border space-y-1 p-2  mb-2 cursor-pointer text-black rounded-xl transition-all ease-in-out hover:bg-white ">
                            <div class="w-full flex flex-col" (click)="showVersion(item)">
                                <span class="font-bold text-md">{{ item.name }}</span>
                                <div class="flex items-center gap-2 ">
                                    <span class="text-gray-500">
                                        {{ item.updatedAt | date: 'mediumDate' }} at
                                        {{ item.updatedAt | date: 'shortTime' }}
                                    </span>
                                    -
                                    <img [src]="item.createdBy | userAvatar" alt="User Avatar"
                                        class="w-4 h-4 rounded-full" />
                                    <span class="text-gray-500">{{item.createdBy | fullName}}</span>
                                </div>

                            </div>
                            <div class="flex-1 justify-end items-center cursor-pointer"
                                (click)="$event.preventDefault();downloadVersion(item.version , $index)">
                                <div class="h-full flex">
                                    <i class="pi pi-download border py-3 px-2.5 rounded-xl text-blue-600 ml-2"></i>
                                </div>
                            </div>
                            <div class="flex-1 justify-end items-center cursor-pointer"
                                (click)="$event.preventDefault();deleteVersion(item.version)">
                                <div class="h-full flex">
                                    <i class="pi pi-trash border py-3 px-2.5 rounded-xl text-red-600 ml-2"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>

            </div>
        </div>
    </ng-template>
</p-dialog>