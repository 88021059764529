<div class="flex items-center justify-between gap-2 w-full">
    <div class="flex items-center">
        <span class="font-bold white-space-nowrap">
            Summary
        </span>
        <span
            class="ml-2 p-2 flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium theme-text ring-1 ring-inset ring-blue-700/10">
            <img src="icons/star.svg" class="mr-1.5 h-3 w-3 text-blue-400" alt="Star icon">
            AI Generated
        </span>
    </div>
    <div class="flex items-center">
        <p-button (onClick)="generateSummary()" [loading]="summaryLoading" [disabled]="summaryLoading"
            loadingIcon="pi pi-refresh" icon="pi pi-refresh" severity="primary"
            class="border rounded-lg py-[6px] mr-1" />
        <p-button [label]=" showSummaryList ? 'Latest' : 'History'" (onClick)=" showOrHideSummaryList()"
            class="border rounded-lg p-[6px]" icon="pi pi-eye" [text]="true" />
    </div>
</div>

@if(showSummaryList) {
<div class="flex flex-col text-sm p-2 pb-6">
    @if(summaryList.length == 0){
    <div class="flex justify-center items-center h-full"> No History </div>
    }@else {
    @for(summary of summaryList; track summary._id) {
    <div class="space-y-2 mt-2">
        <div (click)="fetchSummary(summary._id)"
            class="flex justify-between bg-gray-100 items-center p-2 rounded-xl mb-2 cursor-pointer text-black transition-all ease-in-out hover:bg-white border">
            <div class="flex gap-2">
                <div class="flex flex-col">
                    <div class="flex flex-col">
                        <!-- <span>{{item.name}}</span> -->
                        <p class="text-black font-medium">
                            <span class="text-gray-800 ">
                                {{ summary.updatedAt | date: 'mediumDate' }} at
                                {{ summary.updatedAt | date: 'shortTime' }}
                            </span>
                        </p>
                    </div>
                    <div class="flex gap-2 mt-2">
                        <img [src]="summary.createdBy | userAvatar" alt="User Avatar" class="w-6 h-6 rounded-full" />
                        <span class="text-gray-500">{{summary.createdBy | fullName}}</span>
                    </div>
                </div>
            </div>
            <div>
                <i class="pi pi-ellipsis-v cursor-pointer" (click)="$event.stopPropagation();menu.toggle($event)"></i>
                <p-menu #menu [model]="summaryCardMenu" [popup]="true" appendTo="body">
                    <ng-template pTemplate="item" let-item>
                        <div class="flex items-center py-2 px-3 mt-2"
                            (click)="handleMenuClick($event, summary._id , item);" [style]="item.style">
                            <span [class]="item.icon"></span>
                            <span class="ml-2 options">{{ item.label }}</span>
                        </div>
                    </ng-template>
                </p-menu>
            </div>
        </div>
    </div>
    }
    }
</div>
}
@else {
@if (summaryLoading)
{
<div class="flex justify-center items-center h-full">
    <p-progressSpinner styleClass="my-4" [style]="{width: '30px', height: '30px'}"></p-progressSpinner>
</div>
}
@else{
@if(newSummaryLoading){
<div class="flex">
    <div class="mx-2 mt-2 text-sm text-gray-500">New summary being generated</div>
    <p-progressSpinner styleClass="my-2" [style]="{width: '20px', height: '20px'}"></p-progressSpinner>
</div>
}
<div class="flex flex-col text-sm p-2 pb-6 highlight-transition rounded-lg"
    [ngClass]="updateSummary ? 'bg-blue-100' : ''">
    @if(summary) {
    <div class="prose prose-compact text-justify text-sm prose-ul:pl-1 prose-ol:pl-2 2xl:prose-ul:pl-2 2xl:prose-ol:pl-4"
        [innerHTML]="summary | formatText" dynamicComponentRenderer style="line-height: 1.5rem;">
    </div>
    <!-- <div  [innerHTML]="contractInsights | formatText" class="text-justify"></div> -->
    }
</div>
}
}